html,
body {
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.pi {
  font-size: 1.25em;
}

// Navigation
.navbar-brand {
  font-size: 20px;
}

.navbar-nav .dropdown-menu {
  left: -30px !important;
  z-index: 1010 !important;
}

.navbar-nav a,
.navbar-nav a span {
  font-size: 16px;
}

// Dialogs
.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.p-confirm-dialog {
  max-width: 60em;
}

.p-dialog-header {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: #333333;
  padding: 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

.p-dialog-content {
  background-color: white;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

.p-dialog-header-icons {
  place-content: flex-end;
}

.p-dialog-header-icons button {
  margin-left: 10px;
  color: #848484;
  height: 20px;
  background-color: transparent;
}

.p-dialog-footer {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  margin: 0;
  text-align: right;
  position: relative;
  top: -1px;
  // Put the buttons back as they used to be - so confirm (yes/ok) before reject (no/cancel)
  display: flex;
  flex-direction: row-reverse;
}

.p-dialog-footer .p-button {
  color: #ffffff;
  background-color: #007ad9;
  border: 1px solid #007ad9;
  padding: 6px;
  margin-left: 10px;
}

.p-dialog-footer .p-button:hover {
  background-color: #005b9f;
  border: 1px solid #005b9f;
}

// Date pickers
.p-datepicker-trigger {
  background-color: black;
  color: white;
  border: none;
}

.p-datepicker-trigger .p-button-label {
  padding: 0px;
}

.p-datepicker {
  padding: 0.857em;
  min-width: 20em;
  background-color: white;
  color: black;
  border: 1px solid #c8c8c8;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}

.p-datepicker-prev-icon,
.p-datepicker-next-icon {
  color: #a6a6a6;
}

.p-datepicker-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
}

.p-datepicker-calendar {
  margin: 0.857em 0 0 0;
}

.p-datepicker-calendar th,
.p-datepicker-calendar td {
  padding: 0.5em;
  text-align: center;
}

.p-datepicker-calendar td span {
  padding: 0.5em;
  border-radius: 3px;
  border: none;
}

.p-datepicker-calendar td.p-datepicker-today span {
  background-color: #d0d0d0;
}

.p-datepicker-calendar td span.p-highlight {
  color: #ffffff;
  background-color: #007ad9;
}

.p-datepicker-buttonbar {
  border-top: 1px solid #d8dae2;
  border-left: 0 none;
  border-right: 0 none;
  border-bottom: 0 none;
  padding: 0.5em;
}

.p-datepicker-buttonbar .p-button-text {
  color: #333333;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
}

.p-datepicker .ui-button {
  display: inline-block;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
}

.p-datepicker-buttonbar .p-button-label,
.p-datepicker .ui-button-text {
  padding: 0.429em 1em;
  display: block;
}

// Progress
.p-progressbar {
  border: 0 none;
  height: 24px;
  background-color: #eaeaea;
}

.p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #007ad9;
}

// Toast
.p-toast-message {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 1em 0;
  border: 0 none;
  color: #212121;
}

.p-toast-message-content {
  padding: 0.5em 1em;
}

.p-toast-message-info {
  background-color: #7fbcec;
}

.p-toast-message-success {
  background-color: #b7d8b7;
}

.p-toast-message-warn {
  background-color: #ffe399;
}

.p-toast-message-error {
  background-color: #f8b7bd;
}

.p-toast-message-icon {
  font-size: 2em;
}

.p-toast-message-text {
  padding: 0 0 0.25em 0;
  margin-left: 0.5em;
}

.p-toast-summary {
  font-weight: bold;
  padding: 0 0 0.5em 0;
  display: block;
  font-weight: 700;
  margin-right: 20px;
}

// Data table
.p-datatable {
  background-color: #eaeaea;
  text-align: center;
}

.p-datatable th {
  padding: 2px;
}

.p-datatable td {
  padding: 0px 5px;
}

.p-datatable-header {
  font-weight: 700;
  padding: 0.571em 1em;
}

.p-datatable-thead {
  background-color: #d6d6d6;
}

.p-datatable-thead .p-sortable-column.p-highlight {
  background-color: black;
  color: white;
}

.p-datatable-tbody tr {
  background-color: #ffffff;
  color: #333333;
}

.p-datatable-footer {
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #d6d6d6;
  font-weight: 700;
  color: #333333;
  padding: 0.571em 1em;
  text-align: center;
}

// Sort
.pi-sort-amount-down:before {
  content: "\e913";
}

.pi-sort-amount-up-alt:before {
  content: "\e914";
}

.pi-sort-alt:before {
  content: "\e915";
}

// Paginator
.p-paginator,
.p-paginator .pi,
.p-paginator button {
  font-weight: 400;
  color: #848484;
}

.p-paginator .pi {
  font-size: 16px;
}

.p-paginator .p-inputtext {
  font-weight: normal;
}

.p-paginator .p-paginator-element {
  height: 2.25em;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last {
  min-width: 2.25em;
}

.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-page {
  min-width: 2.25em;
}

.p-paginator .p-paginator-page.p-highlight {
  color: white;
  background-color: black;
}

.p-disabled {
  opacity: 0.5;
  cursor: default !important;
  pointer-events: none;
}

.p-datepicker-calendar .p-disabled {
  background-color: #f0f0f0;
}

.p-datepicker-other-month {
  opacity: 0.5;
}

.p-paginator .pi-angle-left:before {
  content: "\e904";
}

.p-paginator .pi-angle-right:before {
  content: "\e905";
}

.p-paginator .pi-angle-double-left:before {
  content: "\e916";
}

.p-paginator .pi-angle-double-right:before {
  content: "\e917";
}

// Various input styles
input:focus,
textarea:focus,
select:focus,
a:focus,
button:focus,
.btn:focus,
.p-focus,
:focus-visible {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

:focus-visible {
  border-radius: 3px;
}

.p-button-icon-left,
.p-confirm-dialog-icon {
  margin-right: 10px;
}

.p-multiselect,
.p-dropdown,
.p-multiselect-panel,
.p-dropdown-panel,
.p-dropdown-header {
  background-color: white;
}

.p-multiselect-panel,
.p-dropdown-panel {
  border: 1px solid #c8c8c8;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  border-radius: 3px;
}

.p-inputtext {
  padding: 2px 2px 2px 0.429em;
}

.p-dropdown-label.p-inputtext {
  padding-right: 1.5em;
}

.p-multiselect-label,
.p-multiselect-filter,
.p-dropdown-header,
.p-paginator-rpp-options .p-inputtext {
  padding: 2px 2.5em 2px 0.429em;
}

.p-multiselect-label,
.p-multiselect-filter,
.p-dropdown-header,
.p-inputtext {
  font-weight: 700;
}

.p-multiselect-label,
.p-multiselect-filter,
.p-dropdown-header,
.p-inputtext,
.p-multiselect-trigger,
.p-dropdown-trigger,
.p-checkbox-box,
input[type="date"] {
  border: 1px solid #a6a6a6;
}

.p-multiselect-trigger,
.p-dropdown-trigger {
  border-radius: 0px 3px 3px 0px;
}

button,
input,
select,
textarea,
input[type="file"]::-webkit-file-upload-button,
.p-dialog,
.p-checkbox-box,
.p-progressbar,
.p-card,
.p-multiselect,
.p-dropdown,
.p-multiselect-label,
.p-dropdown-label,
.p-sortable-column,
.nav-item a {
  border-radius: 3px;
}

input[type="file"]::-webkit-file-upload-button {
  background-color: black;
  color: white;
  border: none;
  padding: 0.429em;
}

.p-dropdown {
  padding-right: 1.85em;
}

input::-webkit-datetime-edit-fields-wrapper {
  padding: 0px;
}

.p-dialog-header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.p-dialog-footer {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-datatable-header .p-multiselect-label {
  padding: 0.429em 2.5em 0.429em 0.429em;
}

.p-datatable-thead .p-multiselect-label,
.p-datatable-thead .p-dropdown-label {
  text-align: left;
}

.p-multiselect-trigger-icon,
.p-dropdown-trigger-icon {
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  position: absolute;
}

.p-multiselect-trigger,
.p-dropdown-trigger {
  background-color: #ffffff;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #848484;
  cursor: pointer;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.p-dropdown-clear-icon {
  position: absolute;
  right: 2.5em;
  top: 50%;
  font-size: 1em;
  height: 1em;
  margin-top: -0.5em;
}

.p-multiselect-header,
.p-multiselect-item,
.p-dropdown-item {
  padding: 0.429em 0.857em 0.429em 0.857em;
  white-space: normal !important;
  text-align: left;
}

.p-multiselect-item {
  margin: 3px;
}

.p-multiselect-item:hover,
.p-dropdown-item:hover {
  background-color: #eaeaea;
  color: black;
}

.p-dropdown-item.p-highlight {
  color: white;
  background-color: black;
}

.p-checkbox,
.p-checkbox-box {
  margin: 0;
  width: 20px;
  height: 20px;
}

.p-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
}

.p-checkbox-box:not([aria-checked="true"]) {
  background-color: white;
}

.p-checkbox-box[aria-checked="true"],
.p-checkbox-box.p-highlight {
  background-color: black;
  color: white;
}

.p-multiselect-filter,
.p-dropdown-filter {
  background-color: #eaeaea;
  border: none;
}

.p-multiselect-filter-icon,
.p-dropdown-filter-icon {
  right: 0.3em;
}

.p-multiselect-close {
  display: none !important;
}

.p-float-label label {
  left: 0.5em;
}

a,
button {
  cursor: pointer;
}

// give the x back to clear an input defined as type search
input[type="search"]::-webkit-search-cancel-button {
  /*Bootstrap 4 override*/
  -webkit-appearance: revert !important;
}

.brandBar,
.statusSelection {
  width: 100%;
  display: inline-block;
}

.validated.ng-invalid:not(form):not(:required) {
  border-left: 5px solid orange !important;
}
/*
 :required only works on input, select and text area, but that works in our favour
 for p-dropdown as we want to style a inner element
*/
.ng-invalid:required,
input[type="color"].ng-invalid[required],
.ng-invalid[required] .p-multiselect,
.ng-invalid[required] .p-dropdown,
.valueRequired
 {
  border-left: 5px solid #a94442 !important;
}
.validated.ng-valid:not(form),
.ng-valid:required,
input[type="color"].ng-valid[required],
.ng-valid[required] .p-dropdown,
.ng-valid[required] .p-multiselect,
.valueProvided {
  border-left: 5px solid #42a948 !important;
}

.ng-invalid[required] .p-dropdown-label,
.ng-invalid[required] .p-multiselect-label,
.ng-valid[required] .p-multiselect-label,
.ng-valid[required] .p-dropdown-label {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px;
}

input[type="checkbox"][readonly] {
  opacity: 0.5;
}

.loading-indicator {
  position: fixed;
  // z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.crmTooltipDark {
  border-bottom: 1px dotted black;
}

.crmTooltipLight {
  border-bottom: 1px dotted white;
}

.tooltip-inner {
  text-align: left;
  white-space: pre-wrap;
  font-size: 12px;
  background-color: #007bff;
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #007bff;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #007bff;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #007bff;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #007bff;
}

/* Added by Abdul Rauf starts here*/
.formsect {
  background-color: #f5f5f5;
  padding: 20px 20px 0px 20px;
  margin-top: 10px;
  position: relative;
}
.formbar {
  background-color: #333333;
  color: white;
  font-weight: 600;
  padding: 5px 10px 5px 20px;
  min-height: 33px;
  line-height: 1.8;
  margin: -20px -20px 10px -20px;
  z-index: 0;
  position: relative;
}

.custom-container {
  margin: 0px 10px 10px 10px;
}

.form-row.note-list {
  margin-right: 0px;
  margin-left: 0px;
  height: 230px;
  overflow: auto;
}
.note-l {
  list-style: none;
  width: 100%;
  height: auto;
  display: block;
  margin-top: 10px;
  background: white;
  padding: 5px;
}
textarea.notes {
  font-size: 12px;
  color: black;
  font-weight: 700;
  border: 1px solid #c1b8b866;
}

.note-m {
  padding: 0px 3px;
  font-size: 11px;
  font-weight: 600;
  width: calc(100% - 100px);
}

/* Added by abdul rauf ends here */

.p-checkbox-label {
  margin-bottom: 0px !important;
}

.p-column-filter-overlay {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #dee2e6;
  border-radius: 3px;
}

.p-column-filter-buttonbar,
.p-column-filter-operator,
.p-column-filter-add-rule {
  padding: 0.75rem 1.25rem;
}

.p-column-filter-buttonbar button {
  padding: 0.25rem 1rem;
}

.p-column-filter-constraint {
  padding: 1.25rem;
}

.p-column-filter-operator {
  background: #f8f9fa;
}

.p-column-filter-constraint,
.p-column-filter-operator {
  border-bottom: 1px solid #dee2e6;
}

.p-column-filter-overlay .p-button {
  color: #ffffff;
  background-color: #007bff;
  border: 1px solid #007bff;
}

.p-column-filter-overlay .p-button.p-button-outlined {
  color: #007bff;
  background-color: transparent;
  border: 1px solid #007bff;
}

.p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}

.p-column-filter-add-button,
.p-column-filter-remove-button {
  width: 100%;
  padding: 0.25rem;
}

.p-column-filter-remove-button {
  margin-top: 0.5rem;
  padding: 0.25rem;
}

.spinner-border {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.form-group {
  margin-bottom: 0.5rem;
}

.pi-chevron-up:before {
  content: "\e907";
  color: #007ad9;
}

.pi-chevron-down:before {
  content: "\e906";
  color: #007ad9;
}

.pi-chevron-right:before {
  content: "\e905";
  color: #007ad9;
}

.pi-chevron-left:before {
  content: "\e904";
  color: #007ad9;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.p-column-filter-row-items {
  color: white;
  background-color: black;
}

.p-column-filter-row-item {
  padding: 5px;
}

.p-column-filter-row-item.p-highlight {
  background-color: #007bff;
}

button:disabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.careline365 {
  background-color: #CC0033;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 600;
  color: white;
}

.lifeline24 {
  background-color: #006600;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 600;
  color: white;
}

.p-multiselect-label-container {
  display: block;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-leave-animation {
  from {
    background-color: rgba(0, 0, 0, 0.4);
  }
  to {
    background-color: transparent;
  }
}